import {
    GET_SELECTED_WIDGET_ID,
    REQUEST_PARAMS,
    SELECT_PERIOD,
    SELECT_WELL,
    SET_APP_PARAMS_STATUS,
    SET_APP_STATE,
    SET_DIRTY_DATA,
    SET_EDIT_MODE,
    SET_PARAMETER,
    SET_SELECT_TEMPLATE,
    SAVE_APP_STATE,
    LOAD_APP_STATE,
    SYNC_FILTER,
    SET_PORTRAIT_ORIENTATION,
    SET_SELECTED_WELL_DETAILS,
    WELL_UPDATE,
    SET_LAST_UPDATED,
    SYNC_URL_PARAMS,
    SET_LAST_MULTI_WELL_PATH,
    SET_LAST_ROUTE,
    ADD_LAYOUT_MODE,
    REMOVE_LAYOUT_MODE,
    SET_SELECT_WELL_ID,
    SET_NEXT_WELL_ID,
    SET_PROJECT_LOCALE,
} from '@/store/actions/params'
import Vue from "vue"
import {apiCall, hasError} from "@/utils/api"
import {path, simpleWarningNotification} from "@/utils/helpers"
import wells from "@/store/modules/params/wells"
import {v4 as uuidv4} from "uuid"
import _ from "lodash"
import axios from "@/utils/axios"
import moment from "moment"
import {DATA_STATE_RESET} from "@/store/actions/data"
import {WPS_STATE_RESET} from "@/store/actions/wps"
import {REQUEST_AND_APPLY_SINGLE_WELL_PERMISSIONS} from "@/store/actions/auth"
import {SET_HISTORY} from '@/store/actions/history'
import {getHistoryFromStorage} from "@/store/modules/history"
import store from "@/store"
import {LOAD_WIDGET, LOAD_WIDGETS_LIST, SAVE_WIDGET} from "@/store/actions/widgets"
import {REQUEST_WELL} from "@/store/actions/params/wells"
import router from '@/router'
import multiWell from "@/store/modules/params/multiWell.ts"
import {DATE_FORMAT, TIME_FORMAT} from "@/assets/formats"
import {DATA_SOURCE_STAGE, LAYOUT_MODES} from '@/assets/enums'
import {VIEW_SESSION_ID} from '@/assets/strings'
import {getActualPeriod, defaultPeriod} from '@/utils/period.ts'
import {getLocale} from '@/utils/storeDependendHelpers'

const sessionId = uuidv4()



const getDefaultState = () => {
    return {
        app: {
            mode: '',
            isWsConnected: false,
        },
        period: defaultPeriod(),
        appStateLoaded: false,
        editMode: 0,
        dirtyData: 0,
        companies: [],
        session_id: sessionId,
        selected: {},
        selectedWellId: null,
        drawerModelId: null,
        paramsId: null,
        portraitOrientation: false,
        tabIsVisible: true,
        pendingRequests: 0,
        selectedWellDetails: {},
        lastUpdate: null,
        layoutMode: [], // Array<LAYOUT_MODES>
        nextWellId: null,
        projectLocale: null
    }
}

const state = getDefaultState()

axios.defaults.headers.common[VIEW_SESSION_ID] = state.session_id

const filterQueryParams = () => {
    let result = {} // clean up all query params by default
    switch(router.currentRoute.name) {
        case 'file_drive': // for FileDrive we need 'folder_id' query param
            result = _.pick(router.currentRoute.query, ['folder_id'])
            break
    }
    return result
}

async function loadHistoryWell(wellId) {
    const urlQueryParams = new Map(Object.entries(router.currentRoute.query))
    // if route hasn't 'start_date' and 'end_date' query params - proceed history
    if (!urlQueryParams.has('start_date') && !urlQueryParams.has('end_date')) {
        return await getHistoryFromStorage(wellId)
    }
}

async function loadWellPeriod(well) {
    const historyWell = await loadHistoryWell(well.id)
    const savedPeriod = historyWell ? historyWell.period : {}
    const autoUpdate = well.realtime && (!historyWell || historyWell.period.autoUpdate)
    return {
        ...defaultPeriod(),
        ..._.pick(well, ['start_depth', 'end_depth', 'start_date', 'end_date']),
        ...savedPeriod,
        autoUpdate,
    }
}

export async function replaceWellIdInRouter(wellId) {
    if (router.currentRoute.params.well_uid !== wellId) {
        await router.replace({
            name: router.currentRoute.name,
            params: {well_uid: wellId},
            query: filterQueryParams(),
        })
    }
}

const getters = {
    hasAppPendingRequests: state => state.pendingRequests > 0,
    pendingRequestsNumber: state => state.pendingRequests,
    isPortraitOrientation: state => state.portraitOrientation,
    isTabVisible: state => state.tabIsVisible,
    isAppStateLoaded: state => state.appStateLoaded,
    getDrawerModelId: state => state.drawerModelId,
    getAppMode: state => state.app.mode,
    isSelectedWellActual: state => store.getters.getSelectedWellId === state.nextWellId,
    getIsWsConnected: state => state.app.isWsConnected,
    getAppDataLastUpdatedAt: (state, getters) => moment(state.lastUpdate).isValid() ? moment(state.lastUpdate).format(`DD MMM, YYYY ${getters.isLocaleTime24hr ? 'HH:mm' : 'hh:mm A'}`) : '---',
    getViewSessionId: state => state.session_id,
    // Return actual period with latest realtime end_date and end_depth changes from selected well
    // If no well selected return empty object
    getActualPeriod: state => getActualPeriod({
        well: store.getters.getSelectedWell,
        period: state.period,
    }),
    getPeriodDTO: () => ({
        start_date: store.getters.getActualPeriod.start_date,
        end_date: store.getters.getActualPeriod.end_date,
        start_depth: store.getters.getActualPeriod.start_depth,
        end_depth: store.getters.getActualPeriod.end_depth,
    }),
    getDataRequestParams: (state, getters) => {
        const period = getters.getActualPeriod
        if (_.isEmpty(period)) {
            return {}
        }
        return {
            well_id: state.selected.wellId,
            ...period
        }
    },

    getSelectedWellParam: () => param_name =>
        store.getters.getSelectedWell ? _.find(store.getters.getSelectedWell.params, {name: param_name}).value : undefined,
    getSelectedWell: state => {
        const well = state.selectedWellDetails
        return well.id ? {
            ...well,
            path: path(well),
            params: well.params_with_defaults,
        } : {}
    },
    getLocale: (state, getters) => {
        return state.projectLocale || getLocale(getters.getSelectedWell)
    },
    getProjectSettings: (state, getters) => {
        return _.get(getters.getSelectedWell, 'project_setting', {})
    },
    getLocaleDateFormat: (state, getters) => {
        const locale = getters.getLocale || {}
        return _.get(locale, 'date_format.value', DATE_FORMAT)
    },
    getLocaleTimeFormat: (state, getters) => (type = 'short') => {
        const locale = getters.getLocale || {}
        return _.get(locale, `${type}_time_format.value`, TIME_FORMAT)
    },
    getLocaleDateTimeFormat: (state, getters) => (timeType = 'short') => {
        return `${getters.getLocaleDateFormat}, ${getters.getLocaleTimeFormat(timeType)}`
    },
    isLocaleTime24hr: (state, getters) => {
        return getters.getLocale.is24hr
    },
    getLastMultiWellPath: state => {
        return state.selected.lastMultiWellPath
    },
    getLastRoute: state => {
        return state.selected.lastRoute
    },
    getSelectedWellId: state => {
        return state.selectedWellId
    },
    getSelectedProjectId: state => {
        return state.selected.selectedProjectId
    },
    getSelected: state => {
        return state.selected
    },
    getSelectedByViewId: state => data => {
        return state.selected[data]
    },
    getParamsForSync: state => {
        return {
            id: state.paramsId,
            configuration: state.selected,
        }
    },
    getStoredPeriod: state => {
        return _.get(state, 'period', defaultPeriod())
    },
    /* added for get data on active well, for queries who can work with nullable end date */
    getActivePeriod: state => {
        const period = _.get(state, 'period', defaultPeriod())
        return {...period,
            end_date: period.autoUpdate ? null : period.end_date,
            end_depth: period.autoUpdate ? null : period.end_depth
        }
    },

    getCompanies: state => state.companies,

    isEditMode: () => state.editMode > 0,
    isDirtyData: () => state.dirtyData > 0,

    getWellStage: state => {
        //TODO: move well stage property to provide/inject level
        if (state.layoutMode.includes(LAYOUT_MODES.DRILLING)) {
            return DATA_SOURCE_STAGE.DRILLING
        } else if (state.layoutMode.includes(LAYOUT_MODES.COMPLETIONS)) {
            return DATA_SOURCE_STAGE.COMPLETION_FRAC
        }
    },
    getLayoutMode: state => state.layoutMode,
}
const appStateRequestConfig = () => ({
    url: 'backstore/fragment',
    params: {
        class: 'app_state',
    },
})

const actions = {
    [LOAD_APP_STATE]: async ({commit}) => {
        const request = {
            ...appStateRequestConfig(),
            method: 'GET',
        }
        try {
            const resp = await apiCall(request)
            const app_state = _.get(resp.data, 0, {})
            const config = app_state.configuration ? JSON.parse(app_state.configuration) : {}
            if (!_.isEmpty(config)) {
                commit(SET_PARAMETER, {key: 'paramsId', value: app_state.id})
                Object.keys(config)
                    .forEach(key => commit(SET_SELECT_TEMPLATE, {key, value: config[key]}))
            }
            commit(SET_PARAMETER, {key: 'appStateLoaded', value: true})
        } catch (err) {
            hasError({commit}, err)
        }
    },
    [SAVE_APP_STATE]: ({commit}) => {
        if (!state.appStateLoaded) {
            return
        }
        const payload = store.getters.getParamsForSync
        return apiCall({
            ...appStateRequestConfig(),
            method: payload.id ? 'PATCH' : 'POST',
            data: {
                class: 'app_state',
                id: payload.id,
                configuration: JSON.stringify(_.get(payload, 'configuration', {}))
            },
        }).then(resp => {
            commit(SET_PARAMETER, {key: 'paramsId', value: resp.data.id})
        }).catch(err => {
            hasError({commit}, err)
        })
    },
    [GET_SELECTED_WIDGET_ID]: async ({dispatch, state}, payload) => {
        let fragmentId = state.selected[payload.viewId]
        let widget
        if (fragmentId) { // try to get widget settings from store or backend
            const loadedWidget = await dispatch(LOAD_WIDGET, {fragmentId, wellId: store.getters.getSelectedWellId})
            if (loadedWidget?.params.class === payload.class) { // additional widget class check
                widget = loadedWidget
            }
        }
        if (!widget && payload.takeFirstIfNotFound) { // take first of same class
            const widgets = await dispatch(LOAD_WIDGETS_LIST, {class: payload.class})
            widget = widgets[0]
        }
        if (!widget) { // create new if no widget found
            const resp = await dispatch(SAVE_WIDGET, {class: payload.class, label: null, configuration: {name: 'New'}})
            widget = {id: resp.data.fragment_id}
        }
        if (widget.id !== fragmentId) { // save new selected id,
            dispatch(SET_SELECT_TEMPLATE, {key: payload.viewId, value: widget.id})
        }
        return widget.id
    },
    [REQUEST_PARAMS]: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            const request = {
                method: payload.method || 'get',
                url: payload.url,
                data: payload.data,
                params: payload.params,
            }
            apiCall(request)
                .then(resp => {
                    commit(SET_PARAMETER, {key: payload.key, value: resp.data.data})
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [SYNC_FILTER]: (ctx, payload) => {
        if ((payload.start_date !== undefined && !Number.isInteger(payload.start_date)) || (payload.end_date !== undefined && !Number.isInteger(payload.end_date))) {
            console.error('Incorrect payload: start_date or end_date are not in timestamp format')
        }
        return new Promise((resolve, reject) => {
            apiCall({method: 'post', url: '/wells/sync_filter', data: payload})
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [SYNC_URL_PARAMS]: (ctx, payload) => {
        if ((payload.start_date !== undefined && !Number.isInteger(payload.start_date)) || (payload.end_date !== undefined && !Number.isInteger(payload.end_date))) {
            console.error('Incorrect payload: start_date or end_date are not in timestamp format')
        }
        return new Promise((resolve, reject) => {
            apiCall({method: 'post', url: `/wells/${payload.well_id}/well_time`, data: _.pick(payload, ['start_date', 'end_date'])})
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [SELECT_WELL]: async ({commit, dispatch, state}, wellId) => {
        if (state.nextWellId === wellId) {
            return
        }
        commit(SET_NEXT_WELL_ID, wellId)
        if (!wellId) {
            return
        }
        try {
            const well = await dispatch(REQUEST_WELL, {wellId, wellStage: store.getters.getWellStage})
            await dispatch(REQUEST_AND_APPLY_SINGLE_WELL_PERMISSIONS, wellId)
            await replaceWellIdInRouter(wellId)
            const period = await loadWellPeriod(well)

            commit(SET_SELECTED_WELL_DETAILS, well)
            commit(SELECT_PERIOD, period)
            commit(SET_SELECT_WELL_ID, wellId)
        } catch (error) {
            commit(SET_SELECT_WELL_ID, null)
            await replaceWellIdInRouter()
            simpleWarningNotification('Please, select Well on top to load data ...')
        } finally {
            commit(WPS_STATE_RESET)
            commit(DATA_STATE_RESET)
            dispatch(SAVE_APP_STATE)
        }
    },
    [SELECT_PERIOD]: ({commit}, period) => {
        commit(SELECT_PERIOD, period)
        const well = store.getters.getSelectedWell
        const wellId = store.getters.getSelectedWellId
        if (well.id !== wellId) {
            console.warn('SELECT_PERIOD: ', well.id, wellId)
        } else {
            if (wellId) {
                commit(SET_HISTORY, {wellId: wellId, key: 'period', value: period})
            }
        }
    },
    [SET_SELECT_TEMPLATE]: ({commit, dispatch}, params) => {
        commit(SET_SELECT_TEMPLATE, params)
        dispatch(SAVE_APP_STATE)
    },
    [SET_LAST_MULTI_WELL_PATH]: ({dispatch}, value) => {
        dispatch(SET_SELECT_TEMPLATE, {key: 'lastMultiWellPath', value})
    },
    [SET_LAST_ROUTE]: ({dispatch}, value) => {
        dispatch(SET_SELECT_TEMPLATE, {key: 'lastRoute', value})
    },
}

const mutations = {
    [SET_PROJECT_LOCALE]: (state, value) => {
        Vue.set(state, 'projectLocale', value)
    },
    [SET_APP_PARAMS_STATUS]: (state, status) => {
        Vue.set(state, 'paramsStatus', status)
    },
    [SET_APP_STATE]: (state, params) => {
        Vue.set(state, 'app', _.merge(state.app, params))
    },
    [SET_LAST_UPDATED]: (state) => {
        Vue.set(state, 'lastUpdate', moment())
    },
    [SET_PARAMETER]: (state, params) => {
        Vue.set(state, params.key, params.value)
    },
    [SET_EDIT_MODE]: (state, add) => {
        Vue.set(state, 'editMode', add ? state.editMode + 1 : state.editMode <= 0 ? 0 : state.editMode - 1)
    },
    [SET_DIRTY_DATA]: (state, add) => {
        Vue.set(state, 'dirtyData', add ? state.dirtyData + 1 : state.dirtyData <= 0 ? 0 : state.dirtyData - 1)
    },
    [SET_SELECT_TEMPLATE]: (state, params) => {
        Vue.set(state.selected, params.key, params.value)
    },
    [SET_SELECT_WELL_ID]: (state, wellId) => {
        Vue.set(state, 'selectedWellId', wellId)
        Vue.set(state.selected, 'wellId', wellId)
    },
    [SET_SELECTED_WELL_DETAILS]: (state, well) => {
        Vue.set(state, 'selectedWellDetails', well)
    },
    [WELL_UPDATE]: (state, payload) => {
        Vue.set(state, 'selectedWellDetails', {...state.selectedWellDetails, ...payload})
    },
    [SELECT_PERIOD]: (state, period) => {
        const newPeriod = {...state.period, ...period}
        Vue.set(state, 'period', newPeriod)
        localStorage.setItem('params', JSON.stringify({period: newPeriod}))
        sessionStorage.setItem('params', JSON.stringify({period: newPeriod}))
    },
    [SET_PORTRAIT_ORIENTATION]: (state, value) => {
        Vue.set(state, 'portraitOrientation', value)
    },
    [ADD_LAYOUT_MODE]: (state, mode) => {
        if (Array.isArray(mode)) {
            state.layoutMode = [...state.layoutMode, ...mode]
        } else {
            state.layoutMode.push(mode)
        }
    },
    [REMOVE_LAYOUT_MODE]: (state, mode) => {
        const index = state.layoutMode.findIndex(item => item === mode)
        if (index !== -1) {
            state.layoutMode.splice(index, 1)
        }
    },
    [SET_NEXT_WELL_ID]: (state, wellId) => {
        state.nextWellId = wellId
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
    modules: {
        wells,
        multiWell,
    }
}
